@import-normalize;
@import 'common/Fonts';
@import 'common/Variables';
@import 'common/BootstrapOverrides';
@import 'common/Helpers';
@import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

html,
body {
	height: 100%;
	position: relative;
	background-color: $darkestGrey;
	color:$lightestGrey;
}

* {
	font-family: $latoRegular;
}

.react-calendar {
	border: none;
	border-radius: 0.25rem;
	width: 100%;
}

.react-datepicker-wrapper {
	width: 100%;
}

.menu-icon {
	color:$grey;
	font-size:15px;
}
